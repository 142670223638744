import * as Klaro from "klaro/dist/klaro-no-css";

class Consent {
    getClassName() {
        return 'Consent';
    }

    constructor() {
        let self = this;
        window.consent = self;

        const klaroConfig = {
            translations: {
                zz: {
                    privacyPolicyUrl: window.siteconfig.cookiebanner.href
                },
                de: {
                    consentNotice: {
                        description: window.siteconfig.cookiebanner.message,
                        learnMore: 'Optionen'
                    },
                    googleAnalytics: {
                        title: "Google Analytics",
                    },
                    purposes: {
                        essential: "Essenziell",
                        analytics: "Statistiken",
                        marketing: "Marketing",
                    },
                    ok: 'Alles akzeptieren',
                }
            },
            htmlTexts: true,
            mustConsent: false,
            hideDeclineAll: true,
            acceptAll: true,
            services: [
                {
                    name: "essential",
                    purposes: ["essential"],
                    default: true,
                    required: true
                },
                {
                    name: "googleanalytics",
                    title: "Google Analytics",
                    purposes: ["analytics"]
                },

                {
                    name: "onlinereviews",
                    title: "onlinereviews",
                    purposes: ["marketing"]
                    //default: true
                },

                {
                    name: "googlemaps",
                    title: "googlemaps",
                    purposes: ["marketing"]
                    //default: true
                },

                {
                    name: "youtube",
                    title: "Youtube",
                    purposes: ["marketing"]
                    //default: true
                },

                {
                    name: "provenexpert",
                    title: "Proven Experts",
                    purposes: ["marketing"]
                    //default: true
                },

                {
                    name: "facebookPixel",
                    title: "Facebook Pixel",
                    purposes: ["marketing"]
                }
            ],
        };
        window.klaro = Klaro;
        window.klaroConfig = klaroConfig;
        // we set up Klaro with the config
        Klaro.setup(klaroConfig);
    }

}

export default new Consent();
